import React from 'react';
import logo from '../images/logo.jpg';
import {useNavigate} from "react-router-dom";

export default function FooterMobile() {
    const navigate = useNavigate();

    return (
        <div className="bg-[#445a6b] text-center py-6">
            <img src={logo} onClick={() => navigate('/')} alt="NeuroWeight Logo" className="mt-2 mx-auto w-16 h-16 object-contain mb-4" />
            <p className="text-[#c1cbcc] px-8 text-xs">All Rights Reserved. Copyright NeuroWeight Research 2023</p>
            <div className="mt-1 space-x-4 text-xs">
                <a href="/terms-and-conditions" className="text-[#b1bdbf] underline cursor-pointer">Terms and Conditions</a>
                <a href="/privacy-policy" className="text-[#b1bdbf] underline cursor-pointer">Privacy Policy</a>
                <a href="/references" className="text-[#b1bdbf] underline cursor-pointer">References</a>
            </div>
        </div>
    );
}
