import headerImage from "../images/logo.webp";
import Navigation from "../components/Navigation";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import logoImage from "../images/ladyWithBook.png";
import TextSection from "../components/TextSection";
import homeHeaderMobile from "../images/homeHeaderMobile.png";
import FooterMobile from "../components/FooterMobile";
import BannerSection from "../components/BannerSection";
import TestimonialsSection from "../components/Testemonial/TestimonialSection";
import {useState} from "react";


export default function About() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bg-[#f3f4f4] font-myriadPro">
            <section className="md:hidden bg-[#f24738] h-16 w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-start relative">
                {/* Burger Menu (Adjusted Position) */}
                <div className="absolute top-6 right-6">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <div className="h-1 w-6 bg-white mb-1"></div>
                        <div className="h-1 w-6 bg-white mb-1"></div>
                        <div className="h-1 w-6 bg-white"></div>
                    </button>
                </div>

                {/* Mobile Menu Items */}
                <div className={`${isOpen ? 'block' : 'hidden'} fixed top-0 left-0 w-full h-full bg-[#f24738] z-50 flex items-start justify-center pt-20`}>
                    <button onClick={() => setIsOpen(false)} className="absolute top-6 right-6 text-white text-3xl font-bold">&times;</button>
                    <ul className="space-y-8 text-white text-3xl font-semibold">
                        <li><a href="/our-mission" className="hover:underline">Our Mission</a></li>
                        <li><a href="/the-research" className="hover:underline">The Research</a></li>
                        <li><a href="/testimonials" className="hover:underline">Testimonials</a></li>
                        <li><a href="https://www.neuroweightlossplan.com/r/a" className="hover:underline">Get Started</a></li>
                    </ul>
                </div>
            </section>
            <header className="hidden md:block relative bg-cover bg-center overflow-visible w-full">
                <img src={logoImage} alt="Logo" className="hidden md:block w-full h-auto max-h-[550px] xl:max-h-[650px] 2xl:max-h-[1000px]" style={{overflow: 'hidden'}} />
                <div className="absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-10  z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-32 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
                <div className="absolute left-10 md:left-0 top-1/3 md:pt-4 pt-10 md:px-16 lg:px-24">
                    <h1 className="font-myriadProBold text-3xl text-shadow md:text-3xl lg:-mb-2 xl:text-5xl 2xl:text-7xl font-bold tracking-tighter text-white w-full md:w-3/4 lg:w-full">We wrote the book</h1>
                    <h1 className="font-myriadProBold text-3xl text-shadow md:text-3xl xl:text-5xl 2xl:text-7xl font-bold text-white mb-4 tracking-tighter w-full md:w-3/4 lg:w-1/2">on natural weight loss!</h1>
                    <p className="2xl:text-3xl xl:pt-4 font-light font-myriadPro text-white w-full md:w-3/4 lg:w-1/2">
                        New York Times Bestselling Author of Chicken Soup for the Parents’
                        Soul, Raymond Aaron has called it “One of the most transformational
                        experience for anyone that has struggled with weight loss.”
                    </p>
                </div>
            </header>

            <section className="py-12">
                <div className="w-[80%] mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-[#606872] font-myriadProBold tracking-tighter font-extrabold w-full lg:w-2/3 text-xl md:text-2xl lg:text-3xl 2xl:text-5xl mb-4">
                        At the heart of our purpose lies an unwavering
                        mission - to help people around the world reach
                        their natural, healthy weight for a lifetime.
                    </h2>
                    <div className="h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Let’s face it. The concept of dieting, as we know it, falls under the grim umbrella of
                        deprivation and desperation. It often involves restrictive measures and a sense of
                        urgency that compels us to make impulsive decisions. We’ll eagerly jump on that
                        new diet we found on Instagram, or decide from one day to the next to go full
                        ketogenic (keto) or vegan, without really understanding how it will impact our body.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Google “weight loss” for the fun of it, and see how outrageous the whole thing has
                        become. The sheer amount of information is mind-boggling, to say the least. But
                        what makes the task of choosing the right diet for you so challenging is how beliefs
                        and opinions about weight loss, even among experts, can be so contradictory.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Some groups claim that a high-carb, low-fat diet is the right path to weight loss.
                        Others swear by a high-fat, low-carb lifestyle. For some, a juice or charcoal cleanse
                        could do the trick. If that didn’t work, (and it most likely didn’t!), you’ll be happy to
                        learn that there is a never-ending list of diets out there. There is the raw food diet,
                        the macrobiotic diet, the baby food diet, the Beverly Hills diet, the cabbage soup diet,
                        the Shangri-La diet, the dessert with breakfast diet, the clip-your-nose-while-you-eat
                        diet, and the “What Would Jesus Eat?” diet (aka the Maker’s diet or the Bible diet). I
                        kid you not—I haven’t made any of these up!
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        We have spent the last decade in the new and emerging field of Neuroweight, which
                        studies the interrelation between the brain, the body and how biological and
                        psychological processes regulate weight. Our objective was to bridge the gap between
                        science and the mainstream in an effort to make weight loss accessible and effective
                        for everyone.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        In our opinion, weight loss is about fact not fiction. There are immutable biological
                        and physiological laws that govern the way weight is regulated. The only real mystery
                        surrounding weight loss is why we refuse to rely on the one thing that could
                        potentially help us. The solution to our problem comes from within. It comes from
                        understanding how our brains and bodies regulate weight and working with it,
                        instead of against it.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Ultimately, it comes from creating an environment that allows the body to self-
                        regulate at a healthy weight range, naturally.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Central to our mission is the belief in the power of education. We aim to shed light
                        on the latest scientific research, providing evidence-based strategies that promote
                        natural, healthy weight loss.
                    </p>
                    <p className="text-[#70777e] text-xl mb-4">
                        By fostering a deeper understanding of nutrition, exercise, and the interplay between
                        the body and mind, we equip individuals with the means to make informed choices
                        that encourage natural healthy weight.
                    </p>
                    <a href="https://www.neuroweightlossplan.com/r/a" className="items-center text-gray-500 text-2xl font-bold underline">Join our FREE online training ></a>
                </div>
            </section>

            <div className="hidden md:block mt-8">
                <BannerSection />
            </div>

            <div className="hidden md:block 2xl:my-20">
                <TestimonialsSection />
            </div>

            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}