import headerImage from "../images/logo.webp";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
import {useNavigate} from "react-router-dom";


export default function Privacy () {
    const navigate = useNavigate();

    return (
        <div>
            <header className="relative bg-cover  bg-center overflow-visible w-full">
                <div className="absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-10  z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
            </header>

            <section className="pt-48 lg:mt-0 w-[80%] mx-auto max-w-[1800px]">
                <h1 className="mx-auto text-center text-xl font-bold lg:text-2xl">NEUROWEIGHT PRIVACY POLICY
                </h1>
                <p className="mt-12 lg:mt-24">
                    Effective Date: October 4, 2023
                </p>
                <p className="mt-6">
                    E-Raid Marketing Inc. d/b/a NeuroWeight (“NeuroWeight,” “us” or “we”) takes your privacy very seriously. We created this Privacy Policy to provide you with important information about the privacy practices applicable to the services provided by us through the NeuroWeight Platform (the “Platform”) and our website at www.neuroweight.com (collectively, the “Services”). This Privacy Policy describes how we collect, protect, use, disclose and store the information collected through the Services.
                </p>
                <p className="mt-6">
                    Through the Platform, users can participate in the NeuroWeight Program. The NeuroWeight Program involves connecting with NeuroWeight coaches (“Coaches”) and receiving wellness services from the Coaches via telecommunications technologies.
                </p>
                <p className="mt-6">
                    BY DOWNLOADING, ACCESSING, OR USING THE PLATFORM OR SERVICES, AND/OR BY REGISTERING WITH US OR PROVIDING INFORMATION TO US IN CONNECTION WITH THE APPLICATION, YOU ACCEPT THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY, AND YOU HEREBY CONSENT THAT WE WILL COLLECT, USE, AND SHARE YOUR INFORMATION AS SET FORTH IN THIS PRIVACY POLICY.
                </p>
                <p className="mt-6 font-bold">
                    COLLECTION, USE, AND DISCLOSURE OF INFORMATION
                </p>
                <p className="mt-6 font-bold">
                    What is Personal Information?
                </p>
                <p className="mt-6">
                    Personal Information is any information relating to a natural person who is, or can be, identified either directly or indirectly from such information and includes information such as a user’s name, address, telephone number, email address, Internet activity (such as browsing history), or other information directly linked to that person. You are not legally required to provide Personal Information to us, but if you refuse to provide such information, we may not be able to register you to use the Platform.
                </p>
                <p className="mt-6 font-bold">
                    Information that We Collect
                </p>
                <p className="mt-6">
                    NeuroWeight collects Personal Information from users when such users register for and use the Platform. You don't have to use the Platform, but if you do, you acknowledge that NeuroWeight collects and uses your Personal Information for the legitimate interests and purposes described in this Privacy Policy. NeuroWeight collects the following types of Personal Information which you provide to us:
                </p>
                <ul className="mt-6">
                    <li>
                        ●	Registration information related to users, such as name, email address, and telephone number;
                    </li>
                    <li>
                        ●	Health information users may provide;
                    </li>
                    <li>
                        ●	Records and copies of your correspondence (including email addresses), if you contact us; and
                    </li>
                    <li>
                        ●	Details of transactions you carry out through our Platform.
                    </li>
                </ul>
                <p className="mt-6">
                    In addition, from time to time, we may use or augment the Personal Information we have about you with information obtained from other sources, such as public databases, social media platforms, and other third parties.
                </p>
                <p className="mt-6">
                    In addition to collecting Personal Information that you provide to us, we may automatically collect certain Personal Information when you use the Services, such as IP address, information about your browser, device or operating system or similar information. IP addresses are ID numbers for the location of your computer or network on the internet. Log files are recordings of the traffic to and from a server. NeuroWeight servers log IP addresses for systems administration and troubleshooting. We track page hits in the aggregate to track the popularity of pages that people visit. With that information we can improve the quality of our Platform. We may also use or allow third parties to use web beacons on our Platform to monitor the effectiveness of our Services. Web beacons are small, graphic images on web pages, web-based documents, or in email messages that allow us or third parties to monitor who is visiting our Platform or if an email has been read. Web beacons collect the IP address of the device where the web beacon is sent, the URL of the page where the web beacon originates, and the time it was viewed. Web beacons may be linked to your Personal Information. We may also use third-party devices, services, links, or electronic mail to deliver surveys to you, which may use cookies, web beacons, or other technology to collect information about your visits to our Platform (or other websites) in order to present surveys that may be of interest to you.
                </p>

                <p className="mt-6 font-bold">
                    How We May Use Personal Information
                </p>
                <p className="mt-6">
                    We and our third-party service providers may use Personal Information to:
                </p>
                <ul className="mt-6">
                    <li>
                        ●	respond to your inquiries and fulfill your requests;
                    </li>
                    <li>
                        ●	send to you administrative information, including information regarding the Platform, and changes to our terms, conditions, and policies;
                    </li>
                    <li>
                        ●	schedule and provide services related to the scheduling of an appointment; for example, communicate with you regarding your appointments and related services provided by your Coach;
                    </li>
                    <li>
                        ●	send to you marketing communications that we believe may be of interest to you;
                    </li>
                    <li>
                        ●	personalize your experience on the Platform by presenting products and offers tailored to you;
                    </li>
                    <li>
                        ●	further our business purposes, such as quality assessment, data analysis, audits, developing new products, enhancing the Platform, improving our services, identifying usage trends to inform members of the NeuroWeight community which aspects of the Platform might be useful to them, developing tools which help you assess the performance, competence and qualifications of Coaches and conducting training programs for such professionals, and determining the effectiveness of our promotional campaigns; and
                    </li>
                    <li>
                        ●	act as we believe to be necessary or appropriate: (a) under applicable law; (b) to comply with legal process; (c) to respond to requests from public or government authorities; (d) to enforce our Terms of Use; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, or that of our affiliates, you or others; (g) at our discretion under emergency circumstances; or (h) to allow us to pursue available remedies or limit the damages that we may sustain.                    </li>
                </ul>
                <p className="mt-6">
                    We may also use aggregate or de-identified data that is not personally identifiable for any legally permissible purposes without restriction.
                </p>
                <p className="mt-6 font-bold">
                    How Personal Information May Be Disclosed
                </p>
                <p className="mt-6">
                    Your Personal Information may be disclosed to:
                </p>
                <ul className="mt-6">
                    <li>
                        ●	our third-party service providers that provide services such as hosting of the Platform, data analysis, IT services and infrastructure, customer service, email delivery, auditing and other similar services;
                    </li>
                    <li>
                        ●	our affiliates, for the purposes described for Personal Information in this Privacy Policy, including marketing purposes relating to their products and services;
                    </li>
                    <li>
                        ●	third parties, to permit them to send marketing communications to you regarding our products or services or the services of your Coaches;
                    </li>
                    <li>
                        ●	a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer, liquidation or other disposition of all or any portion of our business, assets or stock (including in connection with any bankruptcy or similar proceeding); and
                    </li>
                    <li>
                        ●	a third party as we believe to be necessary or appropriate: (a) under applicable law; (b) to comply with legal process; (c) to respond to requests from public or government authorities; (d) to enforce the Terms of Use; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights, privacy, safety or property, or that of our affiliates, you or others; (g) at our discretion under emergency circumstances; or (h) to allow us to pursue available remedies or limit the damages that we may sustain.
                    </li>
                </ul>
                <p className="mt-6">
                    NeuroWeight does not, to the best of our knowledge, sell or rent Personal Information that we have collected or retained about you to any other third-party for any purpose. Accordingly, we do not offer individuals the ability to “opt-out” of the selling or renting of Personal Information because we do not engage in those practices.
                </p>
                <p className="mt-6">
                    We may also disclose to third parties aggregate or de-identified data that is not personally identifiable for any legally permissible purposes.
                </p>
                <p className="mt-6 font-bold">
                    COOKIES
                </p>
                <p className="mt-6">
                    A cookie is a small piece of information that a website can store on a computer and sometimes later use to recognize a user. The purpose of cookies is to enhance your ability to receive the information you want. In the future, we may use cookies to enhance the experience of users. A “session” cookie expires when the user closes the browser in which the website was viewed. A “persistent” cookie may be used to help save your settings and customizations. As with all cookies, you can personalize you web browser settings to reject session cookies. Though some cookies, that are strictly necessary for site operation, cannot be toggled off.
                </p>
                <p className="mt-6">
                    Some of our business partners with whom we contract to carry out the Services (e.g., website providers) may use their cookies on our website and/or Platform. Although we may not have direct access to or control over such cookies, this Privacy Policy governs the use of cookies by us and such business partners on our website and Platform. We may also allow social media companies (e.g., Facebook) to put “widgets” on our websites. These third-party tools may also be used to track you across websites. For example, so long as you are logged in to Facebook, every time you land on a webpage that has a Facebook widget, Facebook will know you are on that webpage. We do not control the privacy practices of these third parties.
                </p>
                <p className="mt-6 font-bold">
                    THIRD PARTIES
                </p>
                <p className="mt-6">
                    This Privacy Policy does not address, and we are not responsible for, the privacy, information or other practices of any third parties, including Coaches, the manufacturer of your mobile device, and any other third party mobile application or website to which the Platform may contain a link. We encourage you to review the privacy policies of each website and application you visit and use.
                </p>
                <p className="mt-6 font-bold">
                    CHILDREN'S PRIVACY
                </p>
                <p className="mt-6">
                    NeuroWeight is concerned about the safety and privacy of children online. Because of this, we will make all efforts to comply with the Children’s Online Privacy Protection Act of 1998 (“COPPA”). COPPA and its accompanying FTC regulations establish United States federal law that protects the privacy of children using the internet. Further, NeuroWeight Services are intended only for individuals who are 18 years of age or older. However, it is possible that by fraud or deception by others we may receive information given to us or pertaining to minors, including children under 13. If we are notified of this, as soon as we verify the information, we will immediately delete the information from our servers. If you want to notify us of our unauthorized receipt of information by minors, including children under 13, please email info@neuroweight.com.
                </p>
                <p className="mt-6 font-bold">
                    SECURITY
                </p>
                <p className="mt-6">
                    We seek to use reasonable physical, technical and administrative measures to protect Personal Information under our control. Unfortunately, no data transmission over the Internet or data storage system is 100% secure, and therefore we cannot guarantee against all potential security breaches.  If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us in accordance with the “Contacting Us” section below.
                </p>
                <p className="mt-6 font-bold">
                    STORAGE OF DATA
                </p>
                <p className="mt-6">
                    We will only store Personal Information for as long as it is needed to fulfill the purposes for which it was collected, subject to applicable data retention periods imposed upon us by applicable law. This may mean that your Personal Information is stored by us for a number of years, depending on the purpose and need for that data to be processed.
                </p>
                <p className="mt-6 font-bold">
                    UPDATES TO THIS PRIVACY POLICY
                </p>
                <p className="mt-6">
                    We may occasionally update this Privacy Policy at any time.  Any changes to this Privacy Policy will become effective when we make the revised Privacy Policy available through the Platform. We will update the “Effective Date” date at the top of this Privacy Policy if we make any such changes to this Privacy Policy. Your use of the Platform or the submission of any information in connection with the Platform following any change means that you accept the revised Privacy Policy. We encourage you to periodically review this Privacy Policy to stay informed about how we collect, use, and disclose personally identifiable information.
                </p>
                <p className="mt-6 font-bold">
                    CONTACTING US
                </p>
                <p className="mb-12">
                    If you have any questions or comments about this Privacy Policy, please contact us by e-mail at info@neuroweight.com. Please note that e-mail communications are not always secure; so please do not include credit card information or other sensitive information in your e-mail messages to us.
                </p>

            </section>
            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}