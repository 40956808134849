import Navigation from "../components/Navigation";
import headerImage from '../images/logo.webp';
import logoImage from '../images/page2Header.webp';
import BannerSection from "../components/BannerSection";
import Footer from "../components/Footer";
import TestimonialsSection from "../components/Testemonial/TestimonialSection";
import {useNavigate} from "react-router-dom";
import FooterMobile from "../components/FooterMobile";


export default function Page2() {
    const navigate = useNavigate();

    return (
        <div className="bg-[#f3f4f4] font-myriadPro">
            <header className="relative bg-cover bg-center overflow-visible w-full">
                <div className="flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 ">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-32 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
                <img src={logoImage} alt="Logo" className="hidden md:block w-full h-auto mt-4 md:mt-0 max-h-[450px] xl:max-h-[600px] 2xl:max-h-[1000px] overflow-hidden " />
                <div className="hidden md:block absolute top-1/3 px-6 md:px-16 lg:px-24">
                    <h1 className="font-myriadProBold text-shadow text-4xl md:text-6xl 2xl:text-7xl tracking-tighter font-extrabold -mb-1 text-white w-full md:w-3/4 lg:w-1/2">Healthy weight</h1>
                    <h1 className="font-myriadProBold text-shadow text-4xl md:text-6xl 2xl:text-7xl font-extrabold tracking-tighter text-white mb-4 w-full md:w-3/4 lg:w-1/2">is not just a number.</h1>
                    <p className="text-xl 2xl:text-3xl font-bold text-white w-full md:w-3/4 lg:w-1/2">
                        Navigating through the weight loss jungle can be
                        challenging for many people.
                    </p>
                    <p className="text-white 2xl:text-2xl w-full md:w-3/4 lg:w-1/2">
                        Over a decade of research in the science of weight regulation has helped thousands of women
                        go from being frustrated about their weight to getting down to their natural healthy weight for good.
                    </p>
                </div>
            </header>
            <section className="py-12">
                <div className="w-[80%] mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="font-myriadProBold text-[#70777e] text-3xl font-bold mb-4">
                        The number doesn’t tell the whole story.
                    </h2>
                    <div className="h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="mb-4 text-xl text-[#70777e]">
                        It is essential to consider other indicators of health, such as body composition, muscle strength, cardiovascular fitness, flexibility, energy levels, mental well-being, and overall lifestyle habits.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        With all that we have shared to this point, it's essential that we consider what a healthy approach to weight loss and overall health truly entails. The journey to achieving and maintaining a natural healthy weight should not be a sprint but rather a sustainable marathon, rooted in science, self-compassion, and a focus on holistic well-being.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Natural Healthy Weight refers to a weight that is appropriate for your body and is achieved through a balanced and sustainable approach to nutrition and lifestyle. It is the weight range at which a person's body functions optimally, supports overall well-being, and reduces the risk of chronic diseases.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Natural Healthy Weight encompasses physical, mental, emotional, and social well-being. It involves nourishing the body with nutritious foods, engaging in regular physical activity, managing stress, getting enough sleep, and maintaining a positive mindset. Striving for consistency and adopting sustainable, healthy habits that promote overall well-being.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Achieving and maintaining your Natural Healthy Weight range is not just about aesthetics; it's about nurturing your body and mind to thrive. When your body settles into its Natural Healthy Weight range, you experience a range of physical, mental, and emotional benefits that contribute to an overall sense of well-being.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Maintaining Natural Healthy Weight can significantly reduce your risk of chronic health conditions such as heart disease, type 2 diabetes, hypertension, and certain cancers. This promotes longevity and an improved quality of life. It is also associated with better metabolic function, including balanced blood sugar levels and improved insulin sensitivity. It reduces the strain on your heart and blood vessels, leading to better cardiovascular health. It can also lower cholesterol and triglyceride levels. Achieving Natural Healthy Weight range improves lung function and reduces the risk of respiratory issues, such as sleep apnea.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        What we often disregard is the benefits that it has on our mental well-being. Natural Healthy Weight allows you to feel comfortable in your body and can boost self-esteem and self-confidence. This positively impacts various aspects of your life, from relationships to career opportunities.
                    </p>
                    <p className="text-[#70777e] text-xl mb-6">
                        We have spent the last decade researching the science of weight regulation. Over the years, there have been many advances in our understanding of how the human body regulates weight, including the roles of genetics, hormones, metabolism, and environmental factors.
                    </p>
                    <a href="https://www.neuroweightlossplan.com/r/a" className="items-center text-gray-500 text-2xl font-bold underline">Join our FREE online training ></a>
                </div>
            </section>
            <BannerSection />
            <div className="hidden md:block">
                <TestimonialsSection />
            </div>
            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}
