import headerImage from "../images/logo.webp";
import Navigation from "../components/Navigation";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import logoImage from "../images/ourMission.webp";
import celeryMobile from "../images/celeryMobile.webp";
import FooterMobile from "../components/FooterMobile";
import {useState} from "react";


export default function OurMission() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    function handleRedirect() {
        window.open("https://www.neuroweightlossplan.com/r/a", "_blank"); // replace with your URL
    }

    return (
        <div className="bg-[#f3f4f4] font-myriadPro">
            <section className="md:hidden bg-[#f24738] aspect-square w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-start relative">
                {/*<img src={headerImage}*/}
                {/*     onClick={() => navigate('/')}*/}
                {/*     alt="header"*/}
                {/*     className="w-24 2xl:w-32 h-auto absolute top-6 left-6" />*/}
                {/* Burger Menu (Adjusted Position) */}
                <div className="absolute top-12 right-12">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <div className="h-1 w-6 bg-white mb-1"></div>
                        <div className="h-1 w-6 bg-white mb-1"></div>
                        <div className="h-1 w-6 bg-white"></div>
                    </button>
                </div>

                {/* Mobile Menu Items */}
                <div className={`${isOpen ? 'block' : 'hidden'} fixed top-0 left-0 w-full h-full bg-[#f24738] z-50 flex items-start justify-center pt-20`}>
                    <button onClick={() => setIsOpen(false)} className="absolute top-6 right-6 text-white text-3xl font-bold">&times;</button>
                    <ul className="space-y-8 text-white text-3xl font-semibold">
                        <li><a href="/" className="hover:underline">Home</a></li>
                        <li><a href="/our-mission" className="hover:underline">Our Mission</a></li>
                        <li><a href="/the-research" className="hover:underline">The Research</a></li>
                        <li><a href="/testimonials" className="hover:underline">Testimonials</a></li>
                        <li><a href="https://www.neuroweightlossplan.com/r/a" className="hover:underline">Get Started</a></li>
                    </ul>
                </div>

                {/* Section Content */}
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold text-shadow tracking-tighter text-white text-6xl -mb-2 font-extrabold">We're</h2>
                    <h2 className="font-myriadProBold text-shadow tracking-tighter text-white text-6xl -mb-2 font-extrabold">on a</h2>
                    <h2 className="font-myriadProBold text-shadow tracking-tighter text-white text-6xl font-extrabold mb-4">mission.</h2>
                </div>
            </section>
            <header className="hidden md:block relative bg-cover bg-center overflow-visible w-full">
                <div className="hidden md:block max-h-[550px] xl:max-h-[500px] 2xl:max-h-[700px]" style={{overflow: 'hidden'}}>
                    <img src={logoImage} alt="Logo" className="hidden md:block w-full h-auto" />
                </div>
                <div className="hidden md:flex absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-10  z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-32 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
                <div className="hidden md:block left-[10%] pl-10 absolute md:top-8 lg:top-32 -mt-20 mx-auto px-10 sm:px-6 lg:px-8 md:mt-32">
                    <h1 className="font-myriadProBold tracking-tighter text-3xl md:text-3xl lg:text-5xl font-bold text-white text-shadow mb-4 w-full 2xl:text-7xl">We are on a mission.</h1>
                    <p className="lg:text-xl font-bold text-white 2xl:text-2xl w-full md:w-1/2 lg:w-[40%]">
                        Across the world, the prevalence of excess
                        weight and obesity has been steadily increasing,
                        affecting individuals of all ages, genders, and
                        socioeconomic backgrounds.
                    </p>
                </div>
            </header>
            <section className="hidden md:block py-12">
                <div className="w-[80%] mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="tracking-tighter lg:w-[70%] font-myriadProBold text-[#70777e] text-2xl lg:text-3xl font-bold mb-4">
                        At the heart of our purpose lies an unwavering
                        mission - to help people around the world reach
                        their natural, healthy weight for a lifetime.
                    </h2>
                    <div className="h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="mb-4 text-xl text-[#70777e]">
                        With all this, we are faced with a worldwide obesity pandemic that has
                        nearly tripled since the 70’s. There are over 2 billion adults who are
                        overweight, of which 650 million are considered to be obese[172] and we are
                        nowhere near having found a cure. In the United States, 74 percent of
                        adults are overweight, with nearly 43 percent of them who are obese.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Central to our mission is the belief in the power of education. We aim to
                        shed light on the latest scientific research, providing evidence-based
                        strategies that promote natural, healthy weight loss. By fostering a deeper
                        understanding of nutrition, exercise, and the interplay between the body
                        and mind, we equip individuals with the means to make informed choices for their
                        well-being.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        With this will inadvertently come an increase in kidney disease, heart
                        disease, certain types of cancer, blindness, amputation, diabetic infections
                        and a whole range of other diseases, all of which are preventable through
                        proper weight regulation.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        What is even more troubling is that the next generation will most likely be far
                        worse. Childhood obesity has never been higher and continues its steady climb.
                        Data from 2017-2020 shows that 14.7 million children aged 2-19 are now
                        obese.[175] And in a world flooded with too much food and with enormous
                        economic interest in keeping us eating, what is required to turn this ship around is
                        daunting.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        The obesity crisis is so big that we have reached the point in our history where more
                        people are dying from overeating than they are from starvation.[151]
                    </p>
                    <p className="mb-8 text-xl text-[#70777e]">
                        But the biggest problem is that the problem is getting worse, with no cure in sight.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Our mission is to bring radical honesty into the weight loss/weight management
                        segment. In a world filled with so much misinformation and pseudo science, we
                        also believe that everyone should have access to the right information about
                        weight regulation to help them make the right decision about what is best for their
                        body.
                    </p>
                    <p className="text-[#70777e] text-xl mb-6">
                        Ultimately, our mission is more than just helping people reach their natural,
                        healthy weight; it is about igniting a spark of self-discovery and empowerment
                        within each individual. We believe that when people embrace their unique
                        potential and prioritize their well-being, they become agents of positive change in
                        the world. As we stand shoulder to shoulder with those on this transformative
                        path, we are driven by the belief that together, we can create a healthier, happier,
                        and a more vibrant world for all.
                    </p>
                </div>
            </section>

            <section className="md:hidden bg-[#f3f4f4] w-full px-10 sm:px-6 lg:px-8 p-4 flex flex-col sm:flex-row items-center justify-center">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <p className="text-[#445f73] mb-4">There are over 2 billion adults who are
                        overweight, of which 650 million are considered to be obese[172] and we are
                        nowhere near having found a cure.
                    </p>
                    <p className="text-[#445f73] mb-4">Our mission is to bring radical honesty into the weight loss/weight management
                        segment. In a world filled with so much misinformation and pseudo science, we
                        also believe that everyone should have access to the right information about
                        weight regulation to help them make the right decision about what is best for their
                        body.
                    </p>
                    <p className="text-[#445f73] mb-4">Ultimately, our mission is more than just helping people reach their natural,
                        healthy weight; it is about igniting a spark of self-discovery and empowerment
                        within each individual. We believe that when people embrace their unique
                        potential and prioritize their well-being, they become agents of positive change in
                        the world. As we stand shoulder to shoulder with those on this transformative
                        path, we are driven by the belief that together, we can create a healthier, happier,
                        and more vibrant world for all.
                    </p>
                    <h2 className="hidden md:block text-[#445f73] text-4xl font-bold mt-6 mb-4">Everything you know about weightloss is wrong.</h2>
                    <h2 className="font-myriadPro md:hidden text-[#445f73] tracking-tighter text-5xl font-extrabold -mb-1 mt-6">It's all</h2>
                    <h2 className="font-myriadPro md:hidden text-[#445f73] tracking-tighter text-5xl -mb-1 font-extrabold">about Natural</h2>
                    <h2 className="font-myriadPro md:hidden text-[#445f73] tracking-tighter text-5xl font-extrabold mb-4">Healthy Weight</h2>
                    <p className="text-[#445f73] mb-4">
                        Natural Healthy Weight encompasses physical, mental, emotional, and social well-being. It involves nourishing the body with nutritious foods, engaging in regular physical activity, managing stress, getting enough sleep, and maintaining a positive mindset. Striving for consistency and adopting sustainable, healthy habits that promote overall well-being.
                    </p>
                </div>
            </section>

            <section className="md:hidden bg-[#f24738] aspect-square w-full pl-12 lg:px-8 flex items-center">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-2xl">
                    <h2 className="font-myriadProBold text-white tracking-tighter text-5xl font-extrabold -mb-1 mt-6">Natural</h2>
                    <h2 className="font-myriadProBold text-white tracking-tighter text-5xl font-extrabold -mb-1">healthy weight</h2>
                    <h2 className="font-myriadProBold text-white tracking-tighter text-5xl font-extrabold mb-4">starts here.</h2>

                    <a href="https://www.neuroweightlossplan.com/r/a" className="text-white font-bold mt-6 mb-4 underline">>Get started</a>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-3xl tracking-tighter text-[#606872] font-bold">“This program is so different from other weight loss programs out there.”</h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">With other programs, I either had to follow a strict ‘cookie-cutter’ approach that wasn’t sustainable, or I was given a plan and told to go execute it on my own. One of the reasons Neuroweight stands out compared to other programs is because of the level of support and accountability they offer. My coach was there...</p>
                <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Read More</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <header className="md:hidden relative bg-cover bg-center overflow-visible w-full">
                <img src={celeryMobile} alt="Logo" className="w-full h-auto" />

                <div className="absolute left-10 md:left-0 top-0 md:pt-4 pt-16 md:px-16 lg:px-24">
                    <h1 className="font-myriadProBold text-4xl font-extrabold text-white">The number</h1>
                    <h1 className="font-myriadProBold text-4xl font-extrabold text-white"> doesn’t tell the</h1>
                    <h1 className="font-myriadProBold text-4xl font-extrabold text-white"> whole story.</h1>
                    <p className="w-1/2 mt-10 mb-8 text-white">
                        Natural Healthy Weight refers to a weight that is appropriate for your body and is
                        achieved through a balanced and sustainable approach to nutrition and lifestyle. It is
                        the weight range at which a person's body functions optimally, supports well-being, and reduces
                        the risk of chronic diseases.
                    </p>
                    <button
                        onClick={handleRedirect}
                    className="bg-gradient-to-r font-bold from-red-500 to-red-700 text-white border-4 border-white rounded-full px-6 md:px-8 py-2 mt-4 md:mt-8 hover:from-red-600 hover:to-red-800 transition duration-300">
                        GET STARTED
                    </button>
                </div>
            </header>

            <section className="md:hidden bg-[#f24738] aspect-square w-full pl-12 sm:px-6 lg:px-8 p-4 flex items-center justify-start">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadPro text-white text-5xl tracking-tighter font-extrabold mt-6 -mb-1">Free</h2>
                    <h2 className="font-myriadPro text-white text-5xl tracking-tighter font-extrabold -mb-1">online</h2>
                    <h2 className="font-myriadPro text-white text-5xl tracking-tighter font-extrabold -mb-1">training</h2>
                    <h2 className="font-myriadPro text-white text-5xl tracking-tighter font-extrabold">here.</h2>
                    <p onClick={handleRedirect} className="text-white mb-4 underline">>Sign up</p>
                </div>
            </section>

            <section className="md:hidden bg-[#f3f4f4] -mt-6 w-full px-10 sm:px-6 lg:px-8 p-4 flex flex-col sm:flex-row items-center justify-center">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold text-[#445f73] tracking-tighter text-5xl font-bold mt-6 mb-4">Obesity has increased 300% in the last 40 years.</h2>
                </div>
            </section>

            <section className="md:hidden bg-[#839f83] italic aspect-square w-full px-10 lg:px-8 p-4 flex items-center justify-center">
                <div className="text-left mb-4 sm:mb-0">
                    <h2 className="text-white text-xl font-light mt-6 mb-4">"True weightlessness isn't found in shedding pounds,
                        but in letting go of the burdens that weigh down your spirit.
                        Rise above, for you are capable of soaring to incredible heights."
                    </h2>
                    <p className="text-white font-light text-sm mt-6">#WeightlessAndFree</p>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-3xl tracking-tighter text-[#606872] font-bold">“I had no idea how life-changing this journey would be.”</h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    With other programs, I either had to follow a strict ‘cookie-cutter’ approach that wasn’t
                    sustainable, or I was given a plan and told to go execute it on my own. One of the reasons
                    Neuroweight stands out compared to other programs is because of the level of support and
                    accountability they offer. My coach...
                </p>
                <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Read More</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}