import Navigation from "../components/Navigation";
import headerImage from '../images/logo.webp';
import logoImage from '../images/homeHeader.webp';
import homeHeaderMobile from '../images/homeHeaderMobile.webp'
import BannerSection from "../components/BannerSection";
import HalfImageSectionLeft from "../components/HalfImageSectionLeft";
import HalfImageSectionRight from "../components/HalfImageSectionRight";
import orangeShirtLady from "../images/img_1.webp";
import orangeLadyMobile from "../images/orangeLadyMobile.webp";
import leech from "../images/img.webp";
import book from "../images/book.jpg";
import Footer from "../components/Footer";
import TestimonialsSection from "../components/Testemonial/TestimonialSection";
import QuoteSection from "../components/QuoteSection";
import BookSection from "../components/BookSection";
import {useNavigate} from "react-router-dom";
import TestimonialSectionTwo from "../components/Testemonial/TestimonialSectionTwo";
import FooterMobile from "../components/FooterMobile";


export default function Home() {
    const navigate = useNavigate();
    function handleRedirect() {
        window.open("https://www.neuroweightlossplan.com/r/a", "_blank"); // replace with your URL
    }

    return (
        <div className="md:bg-[#f3f4f4] min-h-screen font-myriadPro">
            <header className="relative bg-cover bg-center overflow-visible w-full">
                <div className="max-h-[550px] xl:max-h-[650px] 2xl:max-h-[1000px]" style={{overflow: 'hidden'}}>
                    <img src={logoImage} alt="Logo" className="hidden md:block w-full h-auto" />
                </div>
                <img src={homeHeaderMobile} alt="Logo" className="md:hidden w-full h-auto" />
                <div className="absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-10 2xl:pt-10 z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-40 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
                <div className="absolute left-10 md:left-12 top-1/4 md:top-1/3 2xl:left-20 2xl:top-1/3 md:pt-4 pt-10 md:px-16 lg:px-24">
                    <h1 className="hidden md:block text-shadow text-4xl md:text-3xl font-myriadProBold lg:text-4xl xl:text-6xl font-extrabold text-white tracking-tighter md:w-full 2xl:text-7xl">A different kind</h1>
                    <h1 className="hidden md:block text-shadow text-4xl mb-6 md:text-3xl font-myriadProBold lg:text-4xl xl:text-6xl font-extrabold text-white w-[55%] tracking-tighter md:w-3/4 lg:w-1/3 xl:w-1/2 2xl:w-1/3 2xl:text-7xl">of weight loss.</h1>
                    <h1 className="md:hidden text-4xl text-shadow -mb-1 md:text-3xl font-myriadProBold lg:text-4xl xl:text-6xl font-extrabold text-white w-full tracking-tighter md:w-3/4 lg:w-1/2">A different</h1>
                    <h1 className="md:hidden text-4xl text-shadow -mb-1 md:text-3xl font-myriadProBold lg:text-4xl xl:text-6xl font-extrabold text-white w-full tracking-tighter md:w-3/4 lg:w-1/2">kind of</h1>
                    <h1 className="md:hidden text-4xl text-shadow mb-6 md:text-3xl font-myriadProBold lg:text-4xl xl:text-6xl font-extrabold text-white w-full tracking-tighter md:w-3/4 lg:w-1/2">weight loss.</h1>
                    {/*<p className="hidden md:block md:text-xl font-bold mt-8 text-white w-full md:w-3/4 lg:w-1/2">*/}
                    {/*    We believe that losing weight and keeping it off is*/}
                    {/*    achievable, when you focus on the facts not the fiction.*/}
                    {/*</p>*/}
                    <p className="text-white w-[50%] md:w-1/2 lg:w-1/2 2xl:text-3xl mt-8 mb-4">
                        We believe that losing weight and keeping it off is
                        achievable, when you focus on the facts not the fiction.
                        Over a decade of research in the science of weight regulation has helped thousands of women go from being frustrated about their weight to getting down to their Natural Healthy Weight for good.
                    </p>
                    {/*<button className="hidden md:block bg-gradient-to-r font-bold from-red-500 to-red-700 text-white border-4 border-white rounded-full px-6 md:px-8 py-2 mt-4 md:mt-8 hover:from-red-600 hover:to-red-800 transition duration-300">*/}
                    {/*    GET STARTED*/}
                    {/*</button>*/}
                </div>
            </header>
            <div className="hidden md:block">
                <TestimonialsSection />
            </div>
            <BannerSection />

            <section className="md:hidden bg-[#f3f4f4] -mt-6 w-full px-10 sm:px-6 lg:px-8 p-4 flex flex-col sm:flex-row items-center justify-center">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="text-[#445f73] text-4xl lg:text-5xl tracking-tighter font-myriadProBold font-extrabold mt-6 mb-8">Everything you know about weightloss is wrong.</h2>
                    <p className="text-[#445f73] mb-4">
                        We know how hard it is to lose weight. You are not alone. The average adult will try
                    126 fad diets throughout their lifetime, and usually abandon them
                    within a mere six days.
                    </p>
                    <p className="text-[#445f73] mb-4">The women that we work with never starve themselves, don't spend their
                    days in the gym and have their weight gently settle down naturally at a healthy range.
                    </p>
                    <p className="text-[#445f73] mb-4">Find out how it's done</p>
                    <p className="text-[#445f73] mb-4">Reserve your FREE pass for our next online class.</p>
                </div>

                <a href="https://www.neuroweightlossplan.com/r/a" className="shadow bg-gradient-to-r font-bold from-red-500 to-red-700 text-white border-4 border-white rounded-full px-4 py-2 hover:from-red-600 hover:to-red-800 transition duration-300 self-center sm:mt-0">
                    GET STARTED &rsaquo;&rsaquo;
                </a>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="text-3xl text-[#606872] font-myriadProBold font-bold tracking-tighter">“This program is so different from other weight loss programs out there.”</h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">With other programs, I either had to follow a strict ‘cookie-cutter’ approach that wasn’t sustainable, or I was given a plan and told to go execute it on my own. One of the reasons Neuroweight stands out compared to other programs is because of the level of support and accountability they offer. My coach was there...</p>
                <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Read More</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <BookSection image={book}
                         color="bg-[#f3f4f4]"
                         headerColor="text-[#70777e]"
                         textColor="text-[#606872]"
            />

            <header className="md:hidden relative bg-cover bg-center overflow-visible w-full">
                <img src={orangeLadyMobile} alt="Logo" className="w-full h-auto" />

                <div className="absolute left-10 md:left-0 top-0 md:pt-4 pt-10 md:px-16 lg:px-24">
                    <h1 className="text-5xl font-myriadProBold -mb-2 tracking-tighter font-extrabold text-[#445f73]">The last</h1>
                    <h1 className="text-5xl font-myriadProBold -mb-2 tracking-tighter font-extrabold text-[#445f73]">weight loss</h1>
                    <h1 className="text-5xl font-myriadProBold -mb-2 tracking-tighter font-extrabold text-[#445f73]">program you'll</h1>
                    <h1 className="text-5xl font-myriadProBold tracking-tighter font-extrabold text-[#445f73] mb-2">ever need.</h1>
                    <p className="w-1/2 mt-8 text-[#606872]">
                        Let’s face it, you have probably tried to lose weight in the past and
                        failed. You are not alone.
                    </p>
                    <p className="w-1/2 mt-8 text-[#606872]">
                        The average adult will try 126 fad diets
                        throughout their lifetime, equivalent to trying out two new diets each
                        year! However, these diets are often short-lived, as most people
                        abandon them within a mere six days on average.[8]
                    </p>
                    <button
                        className="bg-gradient-to-r font-bold from-red-500 to-red-700 text-white border-4 border-white rounded-full px-6 md:px-8 py-2 mt-4 md:mt-8 hover:from-red-600 hover:to-red-800 transition duration-300"
                        onClick={handleRedirect}>
                        GET STARTED &rsaquo;&rsaquo;
                    </button>
                </div>
            </header>

            <section className="md:hidden bg-[#f24738] h-[300px] w-full pl-20 sm:pl-6 lg:px-8 p-4 flex items-center">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="text-white tracking-tighter text-5xl font-myriadProBold font-extrabold">Join the</h2>
                    <h2 className="text-white tracking-tighter text-5xl font-myriadProBold font-extrabold">FREE online</h2>
                    <h2 className="text-white text-5xl tracking-tighter font-myriadProBold font-extrabold">training.</h2>
                    <a href="https://www.neuroweightlossplan.com/r/a" className="text-white text-xl mt-2 tracking-tighter underline">>Sign up</a>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="text-2xl text-[#606872] font-myriadProBold font-extrabold">
                    “They were so accessible and responsive to all my requests and questions.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Not only did my coach answer my questions, she made sure I understood why the changes we were making were important for my success or long-term health...
                </p>
                <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Read More</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <div className="hidden md:block">
            <HalfImageSectionRight image={orangeShirtLady}
                                   color="bg-[#fb9c00]"
                                   headerColor="text-white"
                                   textColor="text-white"/>
            </div>
            <div className="hidden md:block">
                <TestimonialSectionTwo />
            </div>
            <div className="hidden md:block">
            <HalfImageSectionLeft image={leech}
                                  color="bg-[#7291a8]"
                                  headerColor="text-white"
                                  textColor="text-white"
            />
            </div>
            <div className="hidden md:block">
                <QuoteSection />
            </div>
            <section className="md:hidden bg-[#839f83] italic aspect-square w-full px-10 lg:px-8 p-4 flex items-center justify-center">
                <div className="text-left mb-4 sm:mb-0">
                    <h2 className="text-white text-xl font-light mt-6 mb-4">“True weightlessness isn't found in shedding pounds,
                        but in letting go of the burdens that weigh down your spirit.
                        Rise above, for you are capable of soaring to incredible heights.”
                    </h2>
                    <p className="text-white font-light text-sm mt-6">#WeightlessAndFree</p>
                </div>
            </section>
            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}
