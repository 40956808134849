import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home";
import Page2 from "./pages/Page2";
import About from "./pages/About";
import Page1 from "./pages/Page1";
import OurMission from "./pages/OurMission";
import Testimonials from "./pages/Testimonials";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import TheResearch from "./pages/TheResearch";
import References from "./pages/References";


function App() {
  return (
      <Router>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/page1" element={<Page1 />} />
            <Route exact path="/page2" element={<Page2 />} />
            <Route exact path="/about-us" element={<About />} />
            <Route exact path="/our-mission" element={<OurMission />} />
            <Route exact path="/testimonials" element={<Testimonials />} />
            <Route exact path="/the-research" element={<TheResearch />} />
            <Route exact path="/references" element={<References />} />

            <Route exact path="/terms-and-conditions" element={<Terms />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
        </Routes>
      </Router>
  );
}

export default App;
