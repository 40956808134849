import headerImage from "../images/logo.webp";
import Navigation from "../components/Navigation";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";
import book from "../images/book.jpg";
import orangeLadyMobile from "../images/orangeLadyMobile.webp";


export default function TheResearch() {
    const navigate = useNavigate();
    const image= book
    const color="bg-[#f3f4f4]"
    const headerColor="text-[#445f73]"

    return (
        <div className="bg-[#f3f4f4] font-myriadPro">
            <header className="relative bg-cover bg-center overflow-visible w-full pb-32">
                <div className="absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-4  z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-32 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
            </header>

            <section className="lg:pt-32 pl-6 flex flex-col md:flex-row w-full h-auto">
                <div className={`lg:mx-auto w-full md:w-1/2 h-full ${color} lg:px-20 p-4 md:p-12 flex flex-col justify-center pt-6 md:pt-0`}>
                    <h2 className={`xl:mt-12 font-myriadProBold tracking-tighter hidden md:block md:${headerColor} lg:max-w-[70%] text-[#445f73] -mb-2 text-3xl 2xl:text-6xl 3xl:text-7xl font-extrabold`}>
                        We wrote the book
                    </h2>
                    <h2 className={`font-myriadProBold tracking-tighter hidden md:block md:${headerColor} lg:max-w-[80%] text-[#445f73] text-3xl 2xl:text-6xl font-extrabold mb-4`}>
                        on natural weight loss!
                    </h2>
                    <h2 className={`md:hidden font-myriadProBold md:text-6xl tracking-tighter ${headerColor} text-[#445f73] text-5xl md:text-4xl font-extrabold`}>
                        We wrote
                    </h2>
                    <h2 className={`md:hidden font-myriadProBold md:text-6xl tracking-tighter ${headerColor} text-[#445f73] text-5xl md:text-4xl font-extrabold`}>
                        the book on
                    </h2>
                    <h2 className={`md:hidden font-myriadProBold md:text-6xl tracking-tighter ${headerColor} text-[#445f73] text-5xl md:text-4xl font-extrabold mb-4`}>
                        weight loss.
                    </h2>
                    <p className={`${headerColor} text-sm 2xl:text-xl mt-4 md:text mb-4 2xl:w-2/3`}>
                        As we introduce this groundbreaking work to the world, our excitement
                        knows no bounds, for we believe that it holds the potential to revolutionize
                        how people see weight management.
                        New York Times Bestselling Author of Chicken Soup for the Parents’
                        Soul, Raymond Aaron has called it “One of the nost transformational
                        experience for anyone that has struggled with weight loss.”
                    </p>
                    <p className={`${headerColor} text-sm 2xl:text-xl md:text`}>
                        New York Times Bestselling Author of Chicken Soup for the Parents’
                        Soul, Raymond Aaron has called it “One of the nost transformational
                        experience for anyone that has struggled with weight loss.”
                    </p>
                    <div className="md:hidden w-full md:w-1/2 h-full md:mt-0">
                        <img src={image} alt="Image is missing" className="h-full w-full object-cover" />
                    </div>
                    <div className="hidden md:block w-full md:w-1/2 h-full md:mt-0">
                        <img src={image} alt="Image is missing" className="h-full max-h-[700px] w-full object-cover" />
                    </div>
                    <p className={`${headerColor} mt-6 text-sm 2xl:text-xl md:text`}>
                        Amidst a landscape inundated with misinformation and pseudoscience, "Weightless" stands as a beacon of authority in the intricate realm of weight loss, providing a refreshingly candid perspective on the entire notion of shedding pounds.
                    </p>
                    <p className={`${headerColor} mt-6 text-sm 2xl:text-xl md:text`}>
                        Drawing on decades of cutting-edge studies in the new and emerging field of Neuroweight, which studies the interrelation between the brain, the body and how biological and psychological processes regulate weight, it gets to the bottom of all your weight loss questions and offers easy, effective solutions.
                    </p>
                    <p className={`${headerColor} mt-6 text-sm 2xl:text-xl md:text`}>
                        Within these pages, you will embark on a transformative journey that transcends mere diet plans and quick fixes. The authors have skillfully navigated the intricate science of weight regulation, presented in an easy to understand fashion that will forever change how you perceive weight loss and its profound impact on our lives.
                    </p>
                    <p className={`${headerColor} mt-6 text-sm 2xl:text-xl md:text`}>
                        Weightless also dives into the brain's mechanisms, illuminating the underlying drivers behind our eating behaviors, emotional connections to food, and self-sabotaging patterns. The insights you will gain from this exceptional work will empower you.
                    </p>
                </div>
            </section>
            <section className="md:hidden bg-[#f24738] aspect-square w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-start relative">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold tracking-tighter text-white text-6xl -mb-1 font-extrabold">Get a</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-6xl -mb-1 font-extrabold">sneak peek</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-6xl font-extrabold mb-4">here.</h2>
                    <a href="https://www.neuroweightlossplan.com/r/a" className="text-white font-bold mb-4 underline">>Get started</a>
                </div>
            </section>

            <header className="md:hidden relative bg-cover bg-center overflow-visible w-full">
                <img src={orangeLadyMobile} alt="Logo" className="w-full h-auto" />

                <div className="absolute left-10 md:left-0 top-0 md:pt-4 pt-10 md:px-16 lg:px-24">
                    <h1 className="text-5xl font-myriadProBold -mb-2 tracking-tighter font-extrabold text-[#445f73]">The last</h1>
                    <h1 className="text-5xl font-myriadProBold -mb-2 tracking-tighter font-extrabold text-[#445f73]">weight loss</h1>
                    <h1 className="text-5xl font-myriadProBold -mb-2 tracking-tighter font-extrabold text-[#445f73]">program you'll</h1>
                    <h1 className="text-5xl font-myriadProBold tracking-tighter font-extrabold text-[#445f73] mb-2">ever need.</h1>
                    <p className="w-1/2 mt-8 text-[#606872]">
                        Let’s face it, you have probably tried to lose weight in the past and
                        failed. You are not alone.
                    </p>
                    <p className="w-1/2 mt-8 text-[#606872]">
                        The average adult will try 126 fad diets
                        throughout their lifetime, equivalent to trying out two new diets each
                        year! However, these diets are often short-lived, as most people
                        abandon them within a mere six days on average.[8]
                    </p>
                    <button
                        onClick={() => window.open('https://www.neuroweightlossplan.com/r/a', '_blank')}
                        className="bg-gradient-to-r font-bold from-red-500 to-red-700 text-white border-4 border-white rounded-full px-6 md:px-8 py-2 mt-4 md:mt-8 hover:from-red-600 hover:to-red-800 transition duration-300">
                        GET STARTED &rsaquo;&rsaquo;
                    </button>
                </div>
            </header>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “Having them there to support me made all the difference in accomplishing my goals.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Throughout my Neuroweight journey, I was never afraid to be honest and open with my coach and my therapist because I knew they were both there to help me learn from my shortcomings. The questions they asked and the strategies they suggested helped me find a way to sustainably reach my goals in a way that FITS my lifestyle, instead of requiring me to change my lifestyle. Through all of the ups and downs of this journey, having them there to support me made all the difference in accomplishing my goals.”
                </p>
                <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Charlotte V.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <section className="md:hidden bg-[#f24738] aspect-square w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-start relative">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold tracking-tighter text-white text-6xl -mb-2 font-extrabold">Try the</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-6xl -mb-2 font-extrabold">first class</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-6xl font-extrabold mb-4">for free.</h2>
                    <a href="https://www.neuroweightlossplan.com/r/a" className="text-white font-bold mb-4 mt-4 underline">>Get started</a>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start pt-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “They were so accessible and responsive to all my requests and questions.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Not only did my coach answer my questions, she made sure I understood why the changes we were making were important for my success or long-term health. The program (and more importantly the accountability I needed to be successful) was tailored to me, which is why I was able to achieve my goals.
                </p>
                <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Jennifer N.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <div className="md:hidden pt-8">
                <FooterMobile />
            </div>
            <div className="hidden md:block">
                <Footer />
            </div>
        </div>
    )
}