// TestimonialsSection.js
import Testimonial from './Testemonial';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function TestimonialsSection() {
    return (
        <Swiper
            className="w-[90%] mt-12"
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 5
                }
            }}
        >
            <SwiperSlide>
                <Testimonial header="“This program is so different from other weight loss programs out there.”" content="With other programs, I either had to follow a strict ‘cookie-cutter’ approach that wasn’t sustainable, or I was given a plan and told to go execute it on my own. One of the reasons Neuroweight stands out compared to other programs is because of the level of support and accountability they offer. My coach was there..." />
            </SwiperSlide>
            <SwiperSlide>
                <Testimonial header="“This is the absolute best, weight management program that I have ever tried.”" content="After my first call with NeuroWeight, they seemed to understand the science behind weight loss, and WOW I was right! provided support in a non-judgemental way - and WOW I was right! I felt comfortable being honest and open with my accountability coach, which led to me really facing the thoughts and actions that..." />
            </SwiperSlide>
            <SwiperSlide>
                <Testimonial header="“I had no idea how life-changing this NeuroWeight journey would be.”" content="My accountability coach is very positive, and she motivates me to push myself outside of my comfort zone to become healthier. Even when I struggle or make mistakes on my journey to health, she helps me get back on track so that I stay focused. I am positive that I never would have made this much progress if I were doing this journey
                ..." />
            </SwiperSlide>
        </Swiper>
    );
}
