// TestimonialsSection.js
import Testimonial from './Testemonial';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

export default function TestimonialSectionTwo() {
    return (
        <Swiper
            className="w-[90%] mt-12"
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={{
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 5
                }
            }}
        >
            <SwiperSlide>
                <Testimonial
                    header="“I was able to lose weight for the first time in years! Thank you NeuroWeight team!”"
                    content="The accountability and coaching that I received on my NeuroWeight journey provided the perfect balance of positivity and encouragement with a touch of tough love (when I needed it). I appreciated the kindness and honesty of both my therapist and my coach. They helped me understand that I am in the..." />
            </SwiperSlide>
            <SwiperSlide>
                <Testimonial
                    header="“The support and the level of accountability I received from Neuroweight is out of this world.”"
                    content="Between the daily reminders in the coaching app, the calls with my Nutritionist, and the text messages and calls with my accountability coach, I never felt alone on this journey. Everyone at NeuroWeight helped me learn from past struggles, so that I was able to overcome some of the obstacles that previously prevented me from..." />
            </SwiperSlide>
            <SwiperSlide>
                <Testimonial
                    header="“Neuroweight provided excellent support and helped change how I approach weight loss!”"
                    content="I appreciate the support and accountability I received as a Neuroweight client. In the past, I’ve tried every diet out there - from keto to Weight Watchers to Whole 30 to Optavia to Jenny Craig. While some of these programs had a weekly meeting or a ‘coach’ that I ordered meals from, I really needed more regular communication..." />
            </SwiperSlide>
        </Swiper>
    );
}
