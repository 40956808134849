export default function BannerSection() {
    return (
        <section className= "font-myriadProBold w-full aspect-square md:aspect-auto md:w-[90%] 2xl:mb-12 py-8 xl:py-6 md:py-3 mx-auto mb-6 px-4 sm:px-6 lg:px-8 bg-[#f24738] p-4 flex text-center flex-col sm:flex-row items-center justify-between">
            <div className="mb-4 sm:mb-0">
                <h2 className="hidden tracking-tighter md:block text-white text-left md:text-left text-5xl md:text-3xl xl:text-5xl max-w-1/2 mx-6 md:mx-4 font-extrabold">Join us for our FREE online Masterclass.</h2>
                <h2 className="md:hidden text-white text-left md:text-left tracking-tighter text-4xl sm:text-5xl mx-6 md:mx-4 font-extrabold">Join us for our</h2>
                <h2 className="md:hidden text-white text-left md:text-left tracking-tighter text-4xl sm:text-5xl mx-6 md:mx-4 font-extrabold">FREE online</h2>
                <h2 className="md:hidden text-white text-left md:text-left tracking-tighter text-4xl sm:text-5xl mx-6 md:mx-4 font-extrabold mb-4">Masterclass.</h2>
                <p className="hidden md:block text-white text-left md:text-left text-xl font-myriadPro font-light max-w-1/2 mx-4">We will be sharing the 5 steps to Natural healthy weight.</p>
                <h3 className="md:hidden font-light font-myriadPro text-white text-2xl text-left md:text-left max-w-1/2 mx-6">Come see what all the hype is about. We have opened up new spots for our FREE online introductory training sessions.</h3>
            </div>
            <a href="https://www.neuroweightlossplan.com/r/a" className="shadow-2xl xl:text-xl xl:px-6 xl:py-3 bg-gradient-to-r font-bold from-red-500 to-red-700 text-white border-4 border-white rounded-full px-4 py-2 hover:from-red-600 hover:to-red-800 transition duration-300 self-center">
                GET STARTED &rsaquo;&rsaquo;
            </a>
        </section>
    )
}
