import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';
import logoImage from '../images/logo.jpg';
import {useNavigate} from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();

    return (
        <footer className="bg-[#455a6b] text-white p-6">
            {/* Top row for social icons and signup button */}
            <div className="flex justify-between items-center mb-4 mx-auto max-w-[2100px]">
                <div className="flex ml-7">
                    <FaFacebookSquare className="w-6 lg:w-8 2xl:w-10 h-auto hover:text-gray-500 mr-2 cursor-pointer" />
                    <FaInstagram className="w-6 lg:w-8 2xl:w-10 h-auto hover:text-gray-500 cursor-pointer" />
                </div>
                <div className="flex items-center space-x-4 mr-6 mt-4">
                    <div className="flex flex-col">
                        <span className="text-lg lg:text-2xl 2xl:text-3xl font-bold">Find out how to get started</span>
                        <p className="text-xs 2xl:text-xl lg:mt-2">Take the first step, sign up for a free information session</p>
                    </div>
                    <a href="https://www.neuroweightlossplan.com/r/a"  className="ml-1 lg:ml-4 bg-gradient-to-b rounded-lg shadow-2xl px-2 py-1 lg:px-4 lg:py-2 text-sm lg:text-xl text-white from-blue-500 to-blue-700 border-2 lg:border-4 border-white hover:text-gray-900 transition duration-300">
                        Sign up &rsaquo;
                    </a>
                </div>
            </div>
            <div className="grid grid-cols-3 mt-4 mx-auto max-w-[2100px]">
                <div className="flex items-end ml-8">
                    <img onClick={() => navigate('/')} src={logoImage} alt="Logo" className="h-16 lg:h-20 w-auto xl:h-24 2xl:h-28" />
                </div>
                <div className="flex items-end mx-auto text-center">
                    <span className="text-xs lg:text-sm 2xl:text-md text-[#b9c4c6]">
                        &copy; All rights reserved. Copyright NeuroWeight Research 2023
                    </span>
                </div>
                <div className="flex items-end space-x-1 text-xs lg:text-sm 2xl:text-md justify-end mr-8">
                    <a href="/terms-and-conditions" className="text-[#b9c4c6] underline cursor-pointer">
                        Terms & Conditions
                    </a>
                    <a href="/privacy-policy" className="text-[#b9c4c6] underline cursor-pointer">
                        Privacy Policy
                    </a>
                    <a href="/references" className="text-[#b9c4c6] underline cursor-pointer">
                        References
                    </a>
                </div>
            </div>
        </footer>
    )
}
