export default function HalfImageSectionRight({ image, color, headerColor, textColor }) {
    return (
        <section className="flex w-full h-auto 2xl:min-h-[550px]">
            <div className={`flex-shrink-0 w-1/2 flex flex-col justify-center ${color} p-10 lg:p-20`}>
                <h2 className={`${headerColor} font-myriadProBold text-2xl lg:text-3xl 2xl:text-6xl font-bold`}>
                    The last weight loss
                </h2>
                <h2 className={`${headerColor} font-myriadProBold text-2xl lg:text-3xl 2xl:text-6xl font-bold mb-4`}>
                    program you’ll ever need.
                </h2>
                <p className={`${textColor} text-sm lg:text-md xl:text-lg 2xl:text-2xl`}>
                    Let’s face it, you have probably tried to lose weight in the past and
                    failed. You are not alone. The average adult will try 126 fad diets
                    throughout their lifetime, equivalent to trying out two new diets each
                    year! However, these diets are often short-lived, as most people
                    abandon them within a mere six days on average.<sup>[8]</sup>
                </p>
                <a href="/page1" className="mt-6 flex items-center lg:space-x-1 text-[#3ac7ec]">
                    <span className="font-bold text-xs lg:text-sm 2xl:text-xl text-white hover:underline">Read More</span>
                    <span className="material-icons text-xs lg:text-sm 2xl:text-xl">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="w-1/2 flex-shrink-0 relative">
                <img src={image} alt="Image is missing" className="absolute top-0 left-0 opacity-80 h-full w-full object-cover object-left" />
            </div>
        </section>
    )
}
