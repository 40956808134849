
export default function BookSection({ image, color, headerColor, textColor, readMoreColor }) {
    return (
        <section className="flex flex-col md:flex-row w-full h-auto">
            <div className="hidden md:block w-full md:w-1/2 h-full md:mt-0">
                <img src={image} alt="Image is missing" className="h-full max-h-[550px] lg:max-h-[550px] xl:max-h-[650px] 2xl:max-h-[650px] w-full object-cover" />
            </div>
            <div className={`justify-center my-auto xl:my-0 pl-12 lg:pl-0 w-full md:w-1/2 h-full ${color} lg:px-20 p-4 md:p-12 flex flex-col justify-center pt-6 md:pt-0`}>
                <h2 className={`xl:mt-12 font-myriadProBold tracking-tighter hidden md:block md:${headerColor} lg:max-w-[70%] text-[#445f73] -mb-2 text-3xl xl:text-5xl 2xl:text-6xl 3xl:text-7xl font-extrabold`}>
                    We wrote the book
                </h2>
                <h2 className={`font-myriadProBold tracking-tighter hidden md:block md:${headerColor} lg:max-w-[80%] text-[#445f73] text-3xl xl:text-5xl 2xl:text-6xl font-extrabold mb-4`}>
                    on natural weight loss!
                </h2>
                <h2 className={`font-myriadProBold tracking-tighter md:hidden md:${headerColor} max-w-[70%] text-[#445f73] text-4xl md:text-4xl font-extrabold`}>
                    We wrote
                </h2>
                <h2 className={`font-myriadProBold tracking-tighter md:hidden md:${headerColor} max-w-[70%] text-[#445f73] text-4xl md:text-4xl font-extrabold`}>
                    the book on
                </h2>
                <h2 className={`font-myriadProBold tracking-tighter md:hidden md:${headerColor} max-w-[80%] text-[#445f73] text-4xl md:text-4xl font-extrabold mb-4`}>
                    weight loss.
                </h2>
                <p className={`${textColor} text-sm xl:text-lg 2xl:text-xl md:text mb-4 2xl:w-2/3`}>
                    As we introduce this groundbreaking work to the world, our excitement
                    knows no bounds, for we believe that it holds the potential to revolutionize
                    how people see weight management.
                    New York Times Bestselling Author of Chicken Soup for the Parents’
                    Soul, Raymond Aaron has called it “One of the most transformational
                    experience for anyone that has struggled with weight loss.”
                </p>
                <p className={`${textColor} text-sm xl:text-lg 2xl:text-xl md:text mb-4`}>
                    Get a sneak peak. Watch the FREE webinar.
                </p>
                <a href="https://www.neuroweightlossplan.com/r/a" className="text-center hidden md:block w-28 bg-gradient-to-b rounded-2xl px-4 py-2 text-white from-blue-500 to-blue-700 hover:text-gray-900 transition duration-300">
                    Sign up
                </a>
            </div>

            <div className="md:hidden w-full md:w-1/2 h-full md:mt-0">
                <img src={image} alt="Image is missing" className="h-full w-full object-cover" />
            </div>
        </section>
    )
}
