import Navigation from "../components/Navigation";
import headerImage from '../images/logo.webp';
import logoImage from '../images/page1Header.webp';
import BannerSection from "../components/BannerSection";
import Footer from "../components/Footer";
import TestimonialsSection from "../components/Testemonial/TestimonialSection";
import {useNavigate} from "react-router-dom";
import FooterMobile from "../components/FooterMobile";


export default function Page1() {
    const navigate = useNavigate();

    return (
        <div className="bg-[#f3f4f4] font-myriadPro">
            <header className="relative bg-cover bg-center overflow-visible w-full">
                <div className="flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4  max-h-[550px] xl:max-h-[500px] 2xl:max-h-[700px]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-32 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
                <img src={logoImage} alt="Logo" className="hidden md:block w-full h-auto mt-4 md:mt-0 max-h-[450px] xl:max-h-[600px] 2xl:max-h-[1000px] overflow-hidden " />
                <div className="hidden md:block absolute top-1/4 pt-4 px-6 md:px-16 lg:px-24">
                    <h1 className="font-myriadProBold text-4xl md:pt-4 md:text-4xl lg:text-5xl 2xl:text-6xl -mb-1 tracking-tighter font-bold text-white w-full">Everything you know about</h1>
                    <h1 className="font-myriadProBold text-4xl md:text-4xl lg:text-5xl font-bold 2xl:text-6xl tracking-tighter text-white mb-4 w-full">weightloss is wrong.</h1>
                    <p className="text-xl font-extrabold text-white w-full md:w-3/4 lg:w-1/2 2xl:text-3xl">
                        The reality is that we see weight
                    </p>
                    <p className="text-xl font-extrabold text-white w-full md:w-3/4 lg:w-1/2 2xl:text-3xl">
                        loss not as it is, but as we think it is.
                    </p>
                    <p className="text-white w-full md:w-[40%] lg:w-[40%] 2xl:text-2xl">
                        Although we may feel that we have the ability to manage
                        our weight at will. The reality is that we really do not. Or at least not
                        in the way we may think.
                    </p>
                </div>
            </header>
            <section className="py-12">
                <div className="w-[80%] mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="font-myriadProBold text-[#70777e] text-3xl font-bold mb-4">
                        The reality is that our struggle is not so much with losing weight, it is with keeping it off over the long haul.
                    </h2>
                    <div className="h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Worldwide prevalence of obesity has nearly tripled since 1975 and is getting worse by the minute. Obesity is now recognised as one of the most important public health problems facing the world today. There are over 2 billion adults who are overweight, of which 650 million are considered to be obese and we are nowhere near having found a solution. In the United States, 74 percent of adults are overweight, nearly 43 percent are obese.                    </p>

                    <p className="mb-4 text-xl text-[#70777e]">
                        **MISSING TEXT** "We see dieting as a grueling..."
                    </p>

                    <p className="mb-4 text-xl text-[#70777e]">
                        We would think that in this age of information, with our collective knowledge, technology, and advancements in science, medicine, psychology and nutrition, we would have gotten a little better at making things better for ourselves. Well, we have not.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        Our biology teases us by thinking that we can somehow lose weight by allowing us to lose a few pounds in the first weeks. But like clockwork, no matter what we do, weight loss plateaus and eventually all the weight that we lost comes up, back until the original weight is regained or worse.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        After all, the way we see weight loss has been shaped by our education from family, teachers, social media, and even our doctors and is most likely wrong.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        For most, the idea of weight loss is a question of eating less and exercising more. While it wouldn’t be completely false to think that if you reduce the amount of calories that you take-in and increase the amount that you burn, you would lose weight, for a while.
                    </p>
                    <p className="mb-4 text-xl text-[#70777e]">
                        What is often left out of the equation is what happens next. Eventually, biology kicks in and weight loss slows  down and becomes increasingly difficult to sustain. It literally feels like the body is fighting back. That’s because it is. Our struggles with weight is not just the “losing the weight” part. Our struggle is being able to get weight off and keep it off over time.
                    </p>
                    <p className="text-[#70777e] text-xl mb-6">
                        With all this said, it should come as no surprise that we are shamefully unsuccessful at losing weight. There is a better way.
                    </p>
                    <a href="https://www.neuroweightlossplan.com/r/a" className="items-center text-gray-500 text-2xl font-bold underline">Find out how ></a>
                </div>
            </section>
            <BannerSection />
            <div className="hidden md:block">
                <TestimonialsSection />
            </div>
            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}
