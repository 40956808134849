export default function QuoteSection() {
    return (
        <div className="flex flex-col 2xl:w-2/3 2xl:mx-auto justify-center md:mx-36 items-start h-72">
            <blockquote className="text-xl md:text-3xl xl:text-4xl 2x:text-7xl tracking-tighter font-semibold italic text-gray-500 mb-4">
                "True weightlessness isn't found in shedding pounds,
                but in letting go of the burdens that weigh down your spirit.
                Rise above, for you are capable of soaring to incredible heights."
            </blockquote>
            <p className="text-lg text-[#606872] tracking-tighter">#WeightlessAndFree</p>
        </div>
    );
}
