import headerImage from "../images/logo.webp";
import Navigation from "../components/Navigation";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import logoImage from "../images/testimonials.webp";
import FooterMobile from "../components/FooterMobile";
import {useState} from "react";


export default function Testimonials() {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    function handleRedirect() {
        window.open("https://www.neuroweightlossplan.com/r/a", "_blank"); // replace with your URL
    }

    return (
        <div className="bg-[#f3f4f4] font-myriadPro">
            <header className="hidden md:block relative bg-cover bg-center overflow-visible w-full">
                <img src={logoImage} alt="Logo" className="w-full h-auto max-h-[550px] xl:max-h-[650px] 2xl:max-h-[1000px]" style={{overflow: 'hidden'}} />
                <div className="absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-4  z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="hidden md:block cursor-pointer w-24 2xl:w-32 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
                <div className="md:left-[10%] absolute md:top-20 lg:top-32 -mt-24 md:mx-auto px-10 sm:px-6 lg:px-8 md:mt-32">
                    <h1 className="hidden text-shadow md:block font-myriadProBold text-xl md:text-3xl lg:text-6xl 2xl:text-7xl font-bold text-white w-full md:w-3/4 lg:w-full">Don’t take our word for it.</h1>
                    <h1 className="md:hidden text-shadow font-myriadProBold text-4xl pt-6 -mb-2 tracking-tighter font-bold text-white w-full md:w-3/4 lg:w-full">Don’t take</h1>
                    <h1 className="md:hidden text-shadow font-myriadProBold text-4xl tracking-tighter font-bold text-white w-full md:w-3/4 lg:w-full">our word for it.</h1>
                    <h1 className="hidden text-shadow md:block font-myriadProBold text-xl md:text-3xl lg:text-6xl font-bold 2xl:text-7xl text-white mb-4 w-full lg:w-2/3">See what people are saying about us.</h1>
                </div>
            </header>

            <section className="md:hidden w-full flex items-center justify-start relative">
                {/*<img src={headerImage}*/}
                {/*     onClick={() => navigate('/')}*/}
                {/*     alt="header"*/}
                {/*     className="w-24 2xl:w-32 h-auto absolute top-6 left-6" />*/}
                {/* Burger Menu (Adjusted Position) */}
                <div className="absolute top-12 right-12">
                    <button onClick={() => setIsOpen(!isOpen)}>
                        <div className="h-1 w-6 bg-white mb-1"></div>
                        <div className="h-1 w-6 bg-white mb-1"></div>
                        <div className="h-1 w-6 bg-white"></div>
                    </button>
                </div>

                {/* Mobile Menu Items */}
                <div className={`${isOpen ? 'block' : 'hidden'} fixed top-0 left-0 w-full h-full bg-[#f24738] z-50 flex items-start justify-center pt-20`}>
                    <button onClick={() => setIsOpen(false)} className="absolute top-6 right-6 text-white text-3xl font-bold">&times;</button>
                    <ul className="space-y-8 text-white text-3xl font-semibold">
                        <li><a href="/our-mission" className="hover:underline">Our Mission</a></li>
                        <li><a href="/the-research" className="hover:underline">The Research</a></li>
                        <li><a href="/testimonials" className="hover:underline">Testimonials</a></li>
                        <li><a href="https://www.neuroweightlossplan.com/r/a" className="hover:underline">Get Started</a></li>
                    </ul>
                </div>

                {/* Section Content */}
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <img src={logoImage} alt="Logo" className="w-full h-auto max-h-[550px] xl:max-h-[650px] 2xl:max-h-[1000px]" style={{overflow: 'hidden'}} />
                </div>
                <div className="absolute top-10 left-10">
                    <h1 className="md:hidden text-shadow font-myriadProBold text-4xl pt-6 -mb-2 tracking-tighter font-bold text-white w-full md:w-3/4 lg:w-full">Don’t take</h1>
                    <h1 className="md:hidden text-shadow font-myriadProBold text-4xl tracking-tighter font-bold text-white w-full md:w-3/4 lg:w-full">our word for it.</h1>
                </div>

            </section>

            <section className="py-4 lg:mt-0">
                <div className="w-full md:w-[80%] mx-auto px-10 sm:px-6 mt-8 lg:px-8">
                    <h2 className="font-myriadProBold text-[#70777e] tracking-tighter text-xl md:text-2xl 2xl:text-4xl font-bold md:mb-20">
                        The transformative impact of the Weightless® weight loss program on the lives of people has been nothing short of
                        awe-inspiring. Here’s what they are saying.
                    </h2>

                    {/* Testimonial 1 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “With NeuroWeight, I was able to have a partner on this journey who was invested in my life and my goals.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        My coach helped me build on my strengths, while helping me tackle my weaknesses in a customizable, manageable way. Couldn’t have done it without them!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Alex V.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 2 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “They were so accessible and responsive to all my requests and questions.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        Not only did my coach answer my questions, she made sure I understood why the changes we were making were important for my success or long-term health. The program (and more importantly the accountability I needed to be successful) was tailored to me, which is why I was able to achieve my goals.
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Jennifer N.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 3 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “I’m pretty sure that I’ve tried practically every diet!”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        I am not new to the weight loss world. In fact, I’m pretty sure that I’ve tried practically every “diet” or weight loss program out there. With the unbelievable personalized plan provided from the nutritionists and coaching team, I was able to approach weight loss from a completely different angle - one that made more common sense - and that allowed me to finally make progress towards my goals (after being stuck for what feels like an eternity).”
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Carole K.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 4 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “I can’t speak highly enough about the level of support I received from Neuroweight.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        From the personalized nutrition that they call “Youtrition” to the incredible coaching that I received, I can’t speak highly enough about the level of support I received from Neuroweight. The reason I struggled to lose weight on other diets and programs was because of lack of clarity, accountability and support. This time around, I had a game plan to follow, the accountability and support to help celebrate my successes, as well as someone to talk through my struggles. Thank you for the personalized support, it helped me achieve my goals!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Vicky B.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 5 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “When it comes down to it, having someone there to help me stay accountable to my actions and goals really helped me to change for the long-term.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        When it came to my nutrition and weight loss goals, I used to alternate between two extremes - ‘I want to lose weight and feel better about myself,’ or ‘I only live life once - I want to enjoy ALL of the delicious foods, all of the time.’ When I was being “good,” my nutrition choices were stellar. But once life threw me a curveball, the ‘screw it’ mentality hit and I fell off the wagon hard. Having an accountability coach who was invested in my life and my goals helped me (for the first time in my life) to stay more consistent and find a better balance between the two extremes. I learned how to eat healthier foods most of the time, while still enjoying some of my favorite less-healthy foods occasionally. When it comes down to it, having someone there to help me stay accountable to my actions and goals really helped me to change for the long-term. Thank you Neuroweight!”
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Nancy R.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 6 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “That was worth every penny!”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        Before signing up with Neuroweight, I was a bit apprehensive at the idea of coaching. I knew I probably needed more personalized support in order to be successful losing weight, but I was also nervous that I was going to feel judged or shamed for some of my choices. My coach was the opposite of judgemental. She was compassionate yet honest, as well as relatable and supportive. Receiving a positive, supportive weight loss coach was one of the best gifts I could’ve given myself. Not only did I have someone who I could bounce ideas off of, I had someone who truly cared about my success and my long-term health. That was worth every penny!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Myranda J.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 7 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “Thank you Neuroweight for providing excellent support and helping change how we approach weight loss!”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        I appreciate the support and accountability I received as a Neuroweight client. In the past, I’ve tried every diet out there - from keto to Weight Watchers to Whole 30 to Optavia to Jenny Craig. While some of these programs had a weekly meeting or a ‘coach’ that I ordered meals from, I really needed more regular communication with a single coach who was invested in my life. BOOM - Neuroweight was the answer to my needs! Between the text message check-ins, coaching calls, and reminders in the app, I was set up for success from Day 1. I definitely had to learn how to hold myself accountable, too! However, knowing that someone was there to celebrate my wins and talk through the challenges on my journey really helped me more than any other diet or program I’ve tried. Thank you Neuroweight for providing excellent support and helping change how we approach weight loss!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Lauren N.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 8 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “I learned that I don’t have to be perfect, I just need to be consistent!”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        I always thought there was some big secret that everyone else (who was fit and healthy) knew that I didn’t. Neuroweight helped me to realize that the biggest “secret” was consistency. The mix of the online classes, the coaching app, along with the help of the nutritionist and my accountability coach really helped me learn to be consistent for the first time in my life. I finally lost weight, and even more importantly, I learned that I don’t have to be perfect, I just need to be consistent!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Chris G.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 9 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “Without the support and accountability I received on this journey, I never would have made it this far on my own.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        Every other time I’ve tried to lose weight, life would throw me a curve ball and I would throw in the towel and give up on my goals. It would turn into a total self-sabotage fest. Because I had the support of my accountability coach and my psychotherapist, I learned how to manage life’s curve balls so that I was able to get back on track after some unexpected life events. If you’ve struggled to lose weight or stay consistent with your eating before, I highly recommend trusting the NeuroWeight team to help!”
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Sophia T.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 10 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “Having them there to support me made all the difference in accomplishing my goals.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        Throughout my Neuroweight journey, I was never afraid to be honest and open with my coach and my therapist because I knew they were both there to help me learn from my shortcomings. The questions they asked and the strategies they suggested helped me find a way to sustainably reach my goals in a way that FITS my lifestyle, instead of requiring me to change my lifestyle. Through all of the ups and downs of this journey, having them there to support me made all the difference in accomplishing my goals.”
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Charlotte V.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 10 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “I was able to lose weight for the first time in years! Thank you NeuroWeight team!”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        The accountability and coaching that I received on my NeuroWeight journey provided the perfect balance of positivity and encouragement with a touch of tough love (when I needed it). I appreciated the kindness and honesty of both my therapist and my coach. They helped me understand that I am in the driver’s seat of my choices, and my results (or lack thereof) are my responsibility. When I wasn’t keeping commitments to myself, they (respectfully) asked what happened and held me accountable to change my actions. Because of this, I was able to lose weight for the first time in years! Thank you NeuroWeight team!                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Ella R.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 11 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “I had no idea how life-changing this journey would be.”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        My accountability coach is very positive, and she motivates me to push myself outside of my comfort zone to become healthier. Even when I struggle or make mistakes on my journey to health, she helps me get back on track so that I stay focused. I am positive that I never would have made this much progress if I were doing this journey on my own (as opposed to having some accountability.) Thank you NeuroWeight coaches!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Mary N.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>

                    {/* Testimonial 11 */}
                    <h2 className="hidden md:block  font-myriadProBold text-[#606872] 2xl:w-2/3 text-3xl font-bold mb-4">
                        “Everything I thought I knew about weight loss was wrong”
                    </h2>
                    <div className="hidden md:block  h-1 w-20 bg-[#f24738] mb-6"></div>
                    <p className="hidden md:block 2xl:w-2/3 mb-4 text-xl text-[#70777e]">
                        All these years I thought that losing weight was a question of starving myself and spending hours in the gym.  Was I ever wrong?  The experts at NeuroWeight taught me how to create the right conditions to allow my body to lose weight safely and naturally. They explained which food was good for me and the one that I needed to avoid.  They coached me through the whole process.  Now I know how to manage my weight and will do so for the rest of my life!
                    </p>
                    <a className="hidden md:flex items-center text-[#3ac7ec] mb-12">
                        <span className="font-bold text-[#606872] hover:underline">Jessy S.</span>
                        <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                    </a>
                </div>
            </section>
            <div className="md:hidden px-10 flex flex-col items-start py-4 md:py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “With NeuroWeight, I was able to have a partner on this journey who was invested in my life and my goals.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    My coach helped me build on my strengths, while helping me tackle my weaknesses in a customizable, manageable way. Couldn’t have done it without them!
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Alex V.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “They were so accessible and responsive to all my requests and questions.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Not only did my coach answer my questions, she made sure I understood why the changes we were making were important for my success or long-term health. The program (and more importantly the accountability I needed to be successful) was tailored to me, which is why I was able to achieve my goals.
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Jennifer N.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “I’m pretty sure that I’ve tried practically every diet!”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    I am not new to the weight loss world. In fact, I’m pretty sure that I’ve tried practically every “diet” or weight loss program out there. With the unbelievable personalized plan provided from the nutritionists and coaching team, I was able to approach weight loss from a completely different angle - one that made more common sense - and that allowed me to finally make progress towards my goals (after being stuck for what feels like an eternity).”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Carole K.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “I can’t speak highly enough about the level of support I received from NeuroWeight.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    From the personalized nutrition that they call “Youtrition” to the incredible coaching that I received, I can’t speak highly enough about the level of support I received from Neuroweight. The reason I struggled to lose weight on other diets and programs was because of lack of clarity, accountability and support. This time around, I had a game plan to follow, the accountability and support to help celebrate my successes, as well as someone to talk through my struggles. Thank you for the personalized support, it helped me achieve my goals!
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Vicky B.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <section className="md:hidden bg-[#f24738] aspect-square w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-start">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold mt-6 -mb-2">See if</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold -mb-2">this is right</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold -mb-2">for you.</h2>
                    <p onClick={handleRedirect} className="text-white font-bold mt-6 mb-4 underline">>Get started</p>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “When it comes down to it, having someone there to help me stay accountable to my actions and goals really helped me to change for the long-term.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    When it came to my nutrition and weight loss goals, I used to alternate between two extremes - ‘I want to lose weight and feel better about myself,’ or ‘I only live life once - I want to enjoy ALL of the delicious foods, all of the time.’ When I was being “good,” my nutrition choices were stellar. But once life threw me a curveball, the ‘screw it’ mentality hit and I fell off the wagon hard. Having an accountability coach who was invested in my life and my goals helped me (for the first time in my life) to stay more consistent and find a better balance between the two extremes. Thank you Neuroweight!”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Nancy R.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “I decided to work with Neuroweight and WOW I was right!”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    After my first call with NeuroWeight, they seemed to understand the science behind weight loss, and WOW I was right! provided support in a non-judgemental way - and WOW I was right! I felt comfortable being honest and open with my accountability coach, which led to me really facing the thoughts and actions that had held me back from losing weight the countless times I had tried in the past. Weight and body image were both sensitive topics for me, and my coach was respectful of me at all times, yet encouraged me to be consistent and patient with myself as I learned to be healthier. My coach helped hold me accountable for my actions, but even more importantly, she taught me how to hold myself accountable so I can continue to be successful long-term. I am so grateful I joined Neuroweight!”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Yesna T.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “Thank you Neuroweight for providing excellent support and helping change how we approach weight loss!”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    I appreciate the support and accountability I received as a Neuroweight client. In the past, I’ve tried every diet out there - from keto to Weight Watchers to Whole 30 to Optavia to Jenny Craig. While some of these programs had a weekly meeting or a ‘coach’ that I ordered meals from, I really needed more regular communication with a single coach who was invested in my life. BOOM - Neuroweight was the answer to my needs! Between the text message check-ins, coaching calls, and reminders in the app, I was set up for success from Day 1. I definitely had to learn how to hold myself accountable, too! However, knowing that someone was there to celebrate my wins and talk through the challenges on my journey really helped me more than any other diet or program I’ve tried. Thank you Neuroweight for providing excellent support and helping change how we approach weight loss!
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872]">Laurence N.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <section className="md:hidden bg-[#f24738] aspect-square w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-center">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold mt-6 -mb-2">Come see</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold -mb-2">what all the</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold -mb-2">hype is about.</h2>
                    <p onClick={handleRedirect} className="text-white font-bold mt-6 mb-4 underline">>Get started</p>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “Without the support and accountability I received on this journey, I never would have made it this far on my own.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Every other time I’ve tried to lose weight, life would throw me a curve ball and I would throw in the towel and give up on my goals. It would turn into a total self-sabotage fest. Because I had the support of my accountability coach and my psychotherapist, I learned how to manage life’s curve balls so that I was able to get back on track after some unexpected life events. If you’ve struggled to lose weight or stay consistent with your eating before, I highly recommend trusting the NeuroWeight team to help!”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Sophia T.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “Having them there to support me made all the difference in accomplishing my goals.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Throughout my Neuroweight journey, I was never afraid to be honest and open with my coach and my therapist because I knew they were both there to help me learn from my shortcomings. The questions they asked and the strategies they suggested helped me find a way to sustainably reach my goals in a way that FITS my lifestyle, instead of requiring me to change my lifestyle. Through all of the ups and downs of this journey, having them there to support me made all the difference in accomplishing my goals.”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Charlotte V.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “I was able to lose weight for the first time in years! Thank you NeuroWeight team!”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    “The accountability and coaching that I received on my NeuroWeight journey provided the perfect balance of positivity and encouragement with a touch of tough love (when I needed it). I appreciated the kindness and honesty of both my therapist and my coach. They helped me understand that I am in the driver’s seat of my choices, and my results (or lack thereof) are my responsibility. When I wasn’t keeping commitments to myself, they (respectfully) asked what happened and held me accountable to change my actions. Because of this, I was able to lose weight for the first time in years! Thank you NeuroWeight team!”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Ella R.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “Everything I thought I knew about weight loss was wrong”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    All these years I thought that losing weight was a question of starving myself and spending hours in the gym.  Was I ever wrong.  The experts at NeuroWeight taught me how to create the right conditions to allow my body to lose weight safely and naturally. They explained which food was good for me and the one that I needed to avoid.  They coached me through the whole process.  Now I know how to manage my weight and will do so for the rest of my life!
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Jessy S.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <section className="md:hidden bg-[#f24738] aspect-square w-full px-10 sm:px-6 lg:px-8 p-4 flex items-center justify-start">
                <div className="text-left mb-4 sm:mb-0 sm:max-w-xl">
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold mt-6 -mb-2">Try a new</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold -mb-2">kind of</h2>
                    <h2 className="font-myriadProBold tracking-tighter text-white text-5xl font-bold -mb-2">weight loss.</h2>
                    <p onClick={handleRedirect} className="text-white font-bold mt-6 mb-4 underline">>Get started</p>
                </div>
            </section>

            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “That was worth every penny!”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    Before signing up with Neuroweight, I was a bit apprehensive at the idea of coaching. I knew I probably needed more personalized support in order to be successful losing weight, but I was also nervous that I was going to feel judged or shamed for some of my choices. My coach was the opposite of judgemental. She was compassionate yet honest, as well as relatable and supportive. Receiving a positive, supportive weight loss coach was one of the best gifts I could’ve given myself. That was worth every penny!

                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Myranda J.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “I had no idea how life-changing this journey would be.”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    My accountability coach is very positive, and she motivates me to push myself outside of my comfort zone to become healthier. Even when I struggle or make mistakes on my journey to health, she helps me get back on track so that I stay focused. I am positive that I never would have made this much progress if I were doing this journey on my own (as opposed to having some accountability.) Thank you NeuroWeight coaches!
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Mary N.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="font-myriadProBold text-2xl text-[#606872] font-bold">
                    “I learned that I don’t have to be perfect, I just need to be consistent!”
                </h3>
                <div className="h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    “I always thought there was some big secret that everyone else (who was fit and healthy) knew that I didn’t. Neuroweight helped me to realize that the biggest “secret” was consistency. The mix of the online classes, the coaching app, along with the help of the nutritionist and my accountability coach really helped me learn to be consistent for the first time in my life. I finally lost weight, and even more importantly, I learned that I don’t have to be perfect, I just need to be consistent!”
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Chris G.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>
            <div className="md:hidden px-10 flex flex-col items-start py-8 p-4 space-y-4">
                <h3 className="text-2xl text-[#606872] font-bold">
                    “I am positive that I never would have made this much progress if I were doing this journey on my own”
                </h3>
                <div className="font-myriadProBold h-1 w-1/4 bg-[#f24738]"></div>
                <p className="text-left text-sm text-[#606872]">
                    My accountability coach was very positive, and she motivated me to push myself outside of my comfort zone to become a healthier version of myself. Even when I struggled or made mistakes on my journey, she helped me get back on track so that I stayed focused. I am positive that I never would have made this much progress if I were doing this journey on my own. Thank you NeuroWeight coaches!
                </p>
                <a className="flex items-center text-[#3ac7ec]">
                    <span className="font-bold text-[#606872] hover:underline">Amelia J.</span>
                    <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
                </a>
            </div>

            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}