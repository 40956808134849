export default function Testimonial({ header, content }) {
    return (
        <div className="flex flex-col items-start py-8 p-4 space-y-4">
            <h3 className="text-3xl 2xl:text-4xl text-[#606872] font-myriadProBold font-bold tracking-tighter">{header}</h3>
            <div className="h-1 w-1/4 bg-[#f24738]"></div>
            <p className="text-left text-sm 2xl:text-xl text-[#606872]">{content}</p>
            <a href="/testimonials" className="flex items-center text-[#3ac7ec]">
                <span className="font-bold text-[#606872] hover:underline">Read More</span>
                <span className="material-icons align-middle mt-1">keyboard_arrow_right</span>
            </a>
        </div>
    );
}
