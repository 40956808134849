import logoImage from "../images/homeHeader.webp";
import homeHeaderMobile from "../images/homeHeaderMobile.webp";
import headerImage from "../images/logo.webp";
import Navigation from "../components/Navigation";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer";
import FooterMobile from "../components/FooterMobile";


export default function References() {
    const navigate = useNavigate();

    return (
        <div className="md:bg-[#f3f4f4] min-h-screen font-myriadPro">
            <header className="relative bg-cover bg-center overflow-visible w-full">
                <div className="absolute pl-10 top-0 left-0 w-full md:top-0 md:left-0 flex justify-between items-center px-6 md:px-16 lg:px-24 text-white p-4 md:pt-4 pt-10 2xl:pt-10 z-10 md:p-6 md:bg-[#f3f4f4]">
                    <img src={headerImage}
                         onClick={() => navigate('/')}
                         alt="header"
                         className="cursor-pointer w-24 2xl:w-40 h-auto md:absolute md:top-10" />
                    <Navigation />
                </div>
            </header>
            <div className="bg-white w-full pb-12">
                <section className="pt-48 lg:mt-0 w-[80%] mx-auto max-w-[1800px]">
                    <h1 className="mx-auto text-left text-xl font-bold lg:text-2xl">
                        References
                    </h1>
                    <p className="mt-3 md:mt-6">
                        [8]Anderer, J. (2020, January 15). Food Fads: The Average Adult Will Try 126 Different
                        Diets During Their Life
                    </p>
                    <p className="mt-3 md:mt-6">
                        [172] World Obesity Federation. (2016). Prevalence of Obesity. World Obesity
                        Federation.
                    </p>

                    <p className="mt-3 md:mt-6">
                        [172] World Obesity Federation. (2016). Prevalence of Obesity. World Obesity
                        Federation.
                    </p>

                    <p className="mt-3 md:mt-6">
                        [175] Centers for Disease Control and Prevention. (2022, May 17). Childhood Obesity
                        Facts. Overweight & Obesity.
                    </p>

                    <p className="mt-3 md:mt-6">
                        [151] The World Counts. (2023). Statistics About Obesity. The World Counts.
                    </p>

                </section>
            </div>
            <div className="hidden md:block">
                <Footer />
            </div>
            <div className="md:hidden">
                <FooterMobile />
            </div>
        </div>
    )
}