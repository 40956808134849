import { useState } from "react";

export default function Navigation() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="relative mr-4 md:mr-0 md:left-1/2">
            {/* Hamburger Menu */}
            <div className="md:hidden block">
                <button onClick={() => setIsOpen(true)}>
                    <div className="h-1 w-6 bg-white mb-1"></div>
                    <div className="h-1 w-6 bg-white mb-1"></div>
                    <div className="h-1 w-6 bg-white"></div>
                </button>
            </div>

            {/* Desktop Menu Items */}
            <ul className="hidden md:flex space-x-4 lg:space-x-8 xl:space-x-16 text-[#486174] lg:text-xl 2xl:text-2xl mt-6 font-semibold">
                <li><a href="/about-us" className="hover:underline">About Us</a></li>
                <li><a href="/our-mission" className="hover:underline">Our Mission</a></li>
                <li><a href="/testimonials" className="hover:underline">Testimonials</a></li>
                <li><a href="https://www.neuroweightlossplan.com/r/a" className="hover:underline">Get Started</a></li>
            </ul>

            {/* Mobile Menu Items */}
            <div className={`${isOpen ? 'block' : 'hidden'} fixed top-0 left-0 w-full h-full bg-[#f24738] z-50 flex items-start justify-center pt-20`}>
                <button onClick={() => setIsOpen(false)} className="absolute top-6 right-6 text-white text-3xl font-bold">&times;</button>
                <ul className="space-y-8 text-white text-3xl font-semibold">
                    {/*<li><a href="/about-us" className="hover:underline">About Us</a></li>*/}
                    <li><a href="/our-mission" className="hover:underline">Our Mission</a></li>
                    <li><a href="/the-research" className="hover:underline">The Research</a></li>
                    <li><a href="/testimonials" className="hover:underline">Testimonials</a></li>
                    <li><a href="https://www.neuroweightlossplan.com/r/a" className="hover:underline">Get Started</a></li>
                </ul>
            </div>
        </nav>
    );
}
