
export default function HalfImageSectionLeft({ image, color, headerColor, textColor }) {
    return (
        <section className="flex w-full h-96 2xl:min-h-[550px]">
            <div className="w-1/2 h-full">
                <img src={image} alt="Image is missing" className="h-full w-full object-cover" />
            </div>

            <div className={`w-1/2 h-full ${color} p-20 flex flex-col justify-center`}>
                <h2 className={`${headerColor} font-myriadProBold text-2xl lg:text-3xl 2xl:text-6xl font-bold mb-4`}>
                    Healthy weight is not just a number.
                </h2>
                <p className={`${textColor} text-sm lg:text-md xl:text-lg 2xl:text-2xl`}>
                    Navigating through the weight loss jungle can be challenging for many people.
                    Over a decade of research in the science of weight regulation has helped thousands of women go from being frustrated about their weight to getting down to their natural healthy weight for good.
                </p>
                <a href="/page2" className="mt-6 flex items-center space-x-2 text-[#3ac7ec]">
                    <span className="font-bold text-xs lg:text-sm 2xl:text-xl text-white hover:underline">Read More</span>
                    <span className="material-icons text-xs lg:text-sm 2xl:text-xl">keyboard_arrow_right</span>
                </a>
            </div>
        </section>
    )
}
